import { ACCESSIBILITY, isMD } from '@build/util/constants/constants.js';
import gsap from 'gsap';
import { Draggable, InertiaPlugin } from 'gsap/all';

gsap.registerPlugin(Draggable, InertiaPlugin);

class Carousel {
	public wrapper: HTMLElement;
	public type: string | null;
	public carousel: any;
	public cards: HTMLElement[];
	public arrows: NodeListOf<HTMLButtonElement>;
	public prevArrows: NodeListOf<Element>;
	public nextArrows: NodeListOf<Element>;
	public isAnimating: boolean;
	public maxWidth: number;
	public cardCurrent: HTMLElement;
	public cardNext: HTMLElement;
	public cardNextNext: HTMLElement;
	public timeline: gsap.core.Timeline;

	constructor(carouselWrapper: HTMLElement) {
		this.wrapper = carouselWrapper;

		// Don't initialise the carousel the parent is set to ignore it, or there is no element
		if (!this.wrapper || this.wrapper.closest('.c-carsousel--ignore')) return;

		// Set the data-init attribute to true so we don't initialise the carousel again
		this.wrapper.dataset.init = 'true';

		// Used to prevent multiple animations from happening at once
		this.isAnimating = false;
		// The element to transform
		this.carousel = this.wrapper.querySelector('.c-carousel__cards');
		// Each carousel card
		this.cards = Array.from(
			this.carousel.querySelectorAll('.c-carousel__card'),
		);
		// Navigation arrows
		this.arrows = this.wrapper.querySelectorAll('.c-carousel__btn');
		this.prevArrows = this.wrapper.querySelectorAll('.c-carousel__prev');
		this.nextArrows = this.wrapper.querySelectorAll('.c-carousel__next');

		// Determine the type of carousel to initialise
		this.type = this.wrapper.getAttribute('data-carousel-type');

		if (this.type === 'Stacked') {
			this.stacked();
		} else {
			this.dragging();
		}
	}

	/**
	 * Makes sure we update the draggable x offset when an item is focussed e.g. from keyboard tab
	 */
	private scrollIntoViewOnFocus() {
		this.cards.forEach((card) => {
			card.addEventListener('focusin', () => {
				// Calculate whether the draggable slide area needs to scroll, if so tell it to update
				// This all needs to happen BEFORE the element actually gets focus, otherwise the browser will
				// scroll the element natively and Draggable will be out of sync.
				const cardPos = card.getBoundingClientRect();
				const cPos = this.carousel.getBoundingClientRect();
				const wrapPos = this.wrapper.getBoundingClientRect();
				// Off screen left or right
				if (cardPos.left < 0 || cardPos.right > wrapPos.width) {
					const x =
						cardPos.left < 0
							? Math.min(0, cPos.left - cardPos.left + 40)
							: Math.max(
									-this.maxWidth,
									cPos.left - (cardPos.right - wrapPos.width) - 40,
								);
					this.hideArrows(x);
					gsap.set(this.carousel, {
						x,
						onUpdate: () => {
							Draggable.get(this.carousel)?.update(true);
						},
					});
				}
			});
		});
	}

	/**
	 * Get the width of a given card
	 */
	getCardWidth(s: Element) {
		const width =
			s.getBoundingClientRect().width +
			parseFloat(getComputedStyle(s).marginLeft) +
			parseFloat(getComputedStyle(s).marginRight);

		return Math.ceil(width);
	}

	/**
	 * Get the width of entire carousel
	 */
	getCarouselWidth() {
		return this.cards.reduce(
			(w: number, s) => w + this.getCardWidth(s as Element),
			0,
		);
	}

	setCarouselWidth() {
		// Total width of the carousel we can drag
		// On larger screens it doesn't snap, so remove the wrapper width preventing the carousel from being dragged past the last card
		this.maxWidth = isMD()
			? this.getCarouselWidth() - this.wrapper.scrollWidth
			: this.getCarouselWidth();
		// Set the width of the carousel container
		this.carousel.style.width = `${this.maxWidth}px`;
	}

	/**
	 * Create the 'dragging' type carousel
	 */
	dragging() {
		this.scrollIntoViewOnFocus();
		this.setCarouselWidth();

		// Store each card's position to use for snapping
		let carouselOffset = this.carousel.getBoundingClientRect().left;
		let cardOffsets = this.cards.map((el, i) =>
			i === 0
				? 0
				: -(this.cards[i - 1].getBoundingClientRect().right - carouselOffset),
		);

		Draggable.create(this.carousel, {
			type: 'x',
			inertia: true,
			edgeResistance: 0.8,
			bounds: {
				minX: -this.maxWidth,
				maxX: 0,
			},
			maxDuration: 1,
			onDragStart: () => {
				this.isAnimating = true;
			},
			onDragEnd: () => {
				this.isAnimating = false;
				const endVal = Draggable.get(this.carousel).endX;
				// check if arrows need to be hidden
				this.hideArrows(endVal);
			},
			onClick(e) {
				e.preventDefault();
			},
		});

		const dragger = Draggable.get(this.carousel);

		// Snap on mobile
		if (!isMD()) {
			dragger.vars.snap = cardOffsets;
		}

		// Add event listeners to the arrows
		this.arrows.forEach((arrow) => {
			arrow.addEventListener('click', () => {
				// If we are already animating, return
				if (this.isAnimating) return;

				// Direction we're sliding
				const direction = arrow.classList.contains('c-carousel__next')
					? 'next'
					: 'prev';

				// Get the current x position of the carousel
				const currentX = Number(gsap.getProperty(this.carousel, 'x'));
				const currentSnap = gsap.utils.snap(cardOffsets, currentX);
				const currentIndex = cardOffsets.indexOf(currentSnap);
				const nextIndex = currentIndex + (direction === 'next' ? 1 : -1);
				let newX = 0;
				if (nextIndex >= 0 && nextIndex < cardOffsets.length - 1) {
					// Get the next/prev card offset position
					newX = cardOffsets[nextIndex];
				} else {
					// This is an invalid state, but let's just try move in that direction and see where it'll snap to?
					// Add or Minus the card width to the current x position depending on direction
					const cardWidth = this.getCardWidth(this.cards[0] as HTMLElement);
					newX = currentX + (direction === 'next' ? -cardWidth : cardWidth);
				}

				// Snap to the nearest card position
				let snapTo = gsap.utils.snap(cardOffsets, newX);

				// But don't go further than the max width
				if (snapTo < -this.maxWidth) snapTo = -this.maxWidth;
				else if (snapTo >= 0) snapTo = 0;

				// Check if we need to hide the arrows
				this.hideArrows(snapTo);

				// Animate the carousel to the new x position
				gsap.to(this.carousel, {
					x: snapTo,
					onStart: () => {
						this.isAnimating = true;
					},
					onComplete: () => {
						this.isAnimating = false;
					},
				});
			});
		});

		// If width of the element is smaller than the viewport we don't need a carousel.
		// Hide the arrows and center align the content.
		if (this.maxWidth < 0) {
			this.wrapper.classList.add('c-carousel--no-dragging');
			dragger?.enabled(false);
		}

		// Hide arrow if screen is wider than carousel
		this.hideArrows(this.carousel.getBoundingClientRect().x);

		let to: number | undefined;

		window.addEventListener('resize', () => {
			// Add a delay for performance
			clearTimeout(to);
			to = setTimeout(() => {
				// Update width and maxwidth props
				this.setCarouselWidth();
				// reset the card offsets which have probably shifted
				carouselOffset = this.carousel.getBoundingClientRect().left;
				cardOffsets = this.cards.map((el, i) =>
					i === 0
						? 0
						: -(
								this.cards[i - 1].getBoundingClientRect().right - carouselOffset
							),
				);
				// Snap on mobile
				if (isMD()) {
					delete dragger.vars.snap;
				} else {
					dragger.vars.snap = cardOffsets;
				}

				dragger?.applyBounds({
					minX: -this.maxWidth,
					maxX: 0,
				});
				this.wrapper.classList.toggle(
					'c-carousel--no-dragging',
					this.maxWidth < 0,
				);
				dragger?.enabled(this.maxWidth >= 0);
				this.hideArrows(this.carousel.getBoundingClientRect().x);
			}, 150);
		});
	}

	/**
	 * Create the 'stacked' type carousel
	 */
	stacked() {
		// set the height of the carousel which will be the largest card. This prevents arrows moving up/down as content changes.
		this.carousel.style.height = `${this.carousel.offsetHeight}px`;

		// Add class that updates the layout to stacked
		this.wrapper.classList.add('c-carousel--initialised');

		// make the prev button clickable
		(this.prevArrows[0] as HTMLButtonElement).disabled = false;
		// Set the current card and next, next next cards
		this.toggleStackedClasses();

		// Add event listeners to the arrows
		this.arrows.forEach((arrow) => {
			arrow.addEventListener('click', (e) => {
				e.preventDefault();

				// If we are already animating, return
				if (this.isAnimating) return;

				// Direction we're sliding
				const direction = arrow.classList.contains('c-carousel__next')
					? 'next'
					: 'prev';

				// Get the next element
				// If we're at the end of the carousel, go back to the start
				const nextCard = this.cardCurrent.nextElementSibling
					? this.cardCurrent.nextElementSibling
					: this.cards[0];

				// Flick the card
				this.flickCard(nextCard as HTMLElement, direction);
			});
		});

		// Drag the current card
		this.dragCard();

		// Fix stack card height on window resize
		let to: number | undefined;
		window.addEventListener('resize', () => {
			// Add a delay for performance
			clearTimeout(to);
			to = setTimeout(() => {
				// reset the height of the carousel container
				this.carousel.style.removeProperty('height');
				this.wrapper.classList.remove('c-carousel--initialised');
				this.carousel.style.height = `${this.carousel.offsetHeight}px`;
				this.wrapper.classList.add('c-carousel--initialised');
			}, 150);
		});
	}

	dragCard() {
		// gsap quickSetter for updating the rotation of the current card
		const updateRotation = gsap.quickSetter(
			this.cardCurrent,
			'rotation',
			'deg',
		);
		// gsap quickSetter for updating the opacity of the current card
		const updateCurrentOpacity = gsap.quickSetter(this.cardCurrent, 'css');
		// gsap quickSetter for updating the opacity of the next cards children
		const updateNextOpacity = gsap.quickSetter(
			this.cardNext.querySelectorAll(':scope > *'),
			'css',
		);
		// The bounds for the draggable
		const minX = this.wrapper.clientWidth / -2;
		const maxX = this.wrapper.clientWidth / 2;

		// Needed so we can access the class instance inside the Draggable callbacks
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const _this = this;
		// The offset needed to drag before the card will flick. It will snap back if not dragged far enough.
		const dragOffset = 15;

		Draggable.create(this.cardCurrent, {
			type: 'x',
			inertia: true,
			edgeResistance: 0.8,
			bounds: { minX, maxX },
			onDrag() {
				// x value but always positive
				const x = Math.abs(this.x);
				// offset needs to be negative if we're dragging to the left
				const offset = this.x > 0 ? dragOffset : -dragOffset;

				// If the x value is greater than the dragOffset, start animating out the card
				if (x > dragOffset) {
					// calculate the opacity based on the x value and make sure it's between 0 and 1
					const opacity = Math.max(0, Math.min(1, (x - offset) / maxX));
					// Action the gsap quickSetters
					if (!ACCESSIBILITY.reducedMotion) {
						updateRotation(((this.x - offset) / this.maxX) * 10);
						updateCurrentOpacity({ opacity: 1 - opacity });
						updateNextOpacity({ opacity });
					} else {
						gsap.set(_this.cardNext.querySelectorAll(':scope > *'), {
							opacity: 1,
						});
					}
				}
				// Otherwise, reset the card styles
				else {
					updateRotation(0);
					updateCurrentOpacity(1);
					updateNextOpacity(0);
				}
			},
			onDragStart: () => {
				_this.isAnimating = true;
			},
			onDragEnd() {
				_this.isAnimating = false;
				// If the x value is greater than the offset, flick the card
				// This prevents the card from flicking with a small drag, e.g when swiping down the page
				if (Math.abs(this.endX) > dragOffset) {
					// Put this action outside of this event, otherwise when we kill the draggable then this drag end will be treated as a click
					window.setTimeout(() => {
						if (this.getDirection() === 'left') {
							_this.prevArrows.forEach((prevArrow) =>
								(prevArrow as HTMLElement).click(),
							);
						} else {
							_this.nextArrows.forEach((nextArrow) =>
								(nextArrow as HTMLElement).click(),
							);
						}
					});
				}
				// Otherwise reset the card to its original position
				else {
					gsap.to(this.target, {
						x: 0,
						rotation: 0,
					});
				}
			},
			onClick(e) {
				e.preventDefault();
			},
		});
	}

	flickCard(nextCard: HTMLElement, direction: string = 'prev') {
		if (this.timeline) this.timeline.kill();

		// Prevent animations from happening at the same time
		this.isAnimating = true;
		const draggy = Draggable.get(this.cardCurrent);
		if (draggy) draggy.kill();

		// Update the displayed card number
		const counter = this.wrapper.querySelector('.c-carousel__counter');
		if (counter) counter.innerHTML = nextCard.dataset.index || '';

		// Init the timeline with an onComplete callback to reset the styles
		this.timeline = gsap.timeline({
			onComplete: () => {
				// Allow animations to happen again
				this.isAnimating = false;
				// Update the current, next and next next cards
				this.toggleStackedClasses(nextCard);
				// Reset the styles for each card and its direct children
				this.cards.forEach((card, i) => {
					const el = card as HTMLElement;
					el.removeAttribute('style');
					el.querySelectorAll(':scope > *').forEach((child) => {
						child.removeAttribute('style');
					});
				});

				// Drag the new current card
				this.dragCard();
			},
		});

		// prettier-ignore
		const x = direction === 'prev' ? this.wrapper.clientWidth / -2 : this.wrapper.clientWidth / 2;
		const rotation = direction === 'prev' ? -10 : 10;

		this.timeline
			.to(this.cardCurrent, {
				x,
				rotation,
				opacity: 0,
				clearProps: 'x, rotation',
			})
			.to(
				this.cardNext.querySelectorAll(':scope > *'),
				{
					opacity: 1,
				},
				'<',
			)
			.to(this.cardNext, {
				top: 0,
				left: 0,
			})
			.to(
				this.cardNextNext,
				{
					top: parseInt(getComputedStyle(this.cardNextNext).top, 10) / 2,
					left: parseInt(getComputedStyle(this.cardNextNext).left, 10) / 2,
					backgroundColor: () => {
						const compStyle = getComputedStyle(document.body);
						let bg = compStyle.getPropertyValue('--color-bg') || '#f6f6f6';

						if (this.cardNextNext.classList.contains('theme__bg--primary')) {
							bg = compStyle.getPropertyValue('--color-theme-primary');
						}
						return bg;
					},
				},
				'<',
			);
	}

	toggleStackedClasses(el: HTMLElement = this.cards[0] as HTMLElement) {
		// Remove classes from current set of cards
		this.cardCurrent?.classList.remove('c-carousel__card--current');
		this.cardNext?.classList.remove('c-carousel__card--next');
		this.cardNextNext?.classList.remove('c-carousel__card--next-next');

		// Update the current, next and next next cards
		this.cardCurrent = el;
		this.cardNext = (el.nextElementSibling || this.cards[0]) as HTMLElement;
		this.cardNextNext = (this.cardNext.nextElementSibling ||
			this.cards[0]) as HTMLElement;

		// Add classes to the new set of cards
		this.cardCurrent.classList.add('c-carousel__card--current');
		this.cardNext.classList.add('c-carousel__card--next');
		this.cardNextNext.classList.add('c-carousel__card--next-next');
	}

	/**
	 * Hide the arrows when we are at the start or end of the carousel
	 */
	hideArrows(x: number) {
		// Previous arrow
		this.prevArrows.forEach((prevArrow) => {
			if (x >= 0 && !prevArrow.hasAttribute('disabled')) {
				prevArrow.setAttribute('disabled', 'true');
			} else if (x < 0 && prevArrow.hasAttribute('disabled')) {
				prevArrow.removeAttribute('disabled');
			}
		});

		const maxWidth = this.getCarouselWidth() - this.wrapper.scrollWidth;

		// Next arrow
		this.nextArrows.forEach((nextArrow) => {
			if (x <= -maxWidth && !nextArrow.hasAttribute('disabled')) {
				nextArrow.setAttribute('disabled', 'true');
			} else if (x > -maxWidth && nextArrow.hasAttribute('disabled')) {
				nextArrow.removeAttribute('disabled');
			}
		});
	}
}

export default Carousel;
